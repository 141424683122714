[data-component="OnboardingTest"] {
  position: fixed;
  bottom: 1rem;
  right: 1rem;

  width: 15rem;

  display: flex;
  flex-direction: column;

  padding: 1rem;
  background-color: #f1f1f1;

  gap: .5rem;
  border: 1px solid #ddd;
  border-radius: 10px;

  .buttons {
    gap: .5rem;
    display: flex;
  }
}
