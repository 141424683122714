[data-page="onboarding"] {

  > header {
    margin-bottom: var(--spacing-300);
  }

  .content-wrapper {
    display: flex;
    gap: 1rem;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .recommendation-container {
      flex-basis: 20rem;
      flex-shrink: 0;

      > h2 {
        @apply text-400 leading-[95%];
        font-weight: 700;
      }

      [data-component="RecommendationBox"] {
        margin-bottom: var(--spacing-200);
      }
    }

    .products {

      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

      gap: 1rem;

      > [data-component="CardOffer"] {

      }
    }
  }
}
