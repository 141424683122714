[data-component="RecommendationBox"] {

  border: 1px solid #ddd;
  background-color: var(--color-primary-50);
  min-height: 100px;
  padding: var(--spacing-100) var(--spacing-100);
  border-radius: var(--border-radius-medium);

  .products-list {
    padding: var(--spacing-100) 0;
  }

  .total, .product-row {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-100) 0;
  }

  .total {
    border-top: 2px solid #ccc;
  }

  .product-row {
    > span > input {
      margin-right: var(--spacing-200);
    }
  }

}
